import axios from "axios";

export const getVideos = () =>
  axios
    .get(
      "https://api.antv.tv/videos",
      {
        method: "GET"
      }
    )
    .then(({ data }) => data);
