import { Card, Placeholder } from "react-bootstrap";

export const CardLoading = ({ width }) => (
  <Card style={{ width }}>
    <Card.Img variant="top" src="holder.js/100px180" />
    <Card.Body>
      <Placeholder as={Card.Title} animation="glow">
        <Placeholder xs={6} />
      </Placeholder>
      <Placeholder as={Card.Text} animation="glow">
        <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{" "}
        <Placeholder xs={6} /> <Placeholder xs={8} />
      </Placeholder>
      <Placeholder.Button variant="primary" xs={6} />
    </Card.Body>
  </Card>
);

CardLoading.defaultProps = {
  width: "10rem",
};
