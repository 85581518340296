import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaTwitter, FaFacebook } from "react-icons/fa";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  const date = new Date().getFullYear();

  return (
    <footer className="text-muted bg-pattern">
      <Navbar>
        <Container>
          <Row>
            <Col md="6">
              <h4 className="text-white">Follow Us!</h4>
            </Col>
            <Col xs="2" md="2">
              <a
                href="https://www.twitter.com/ApplausenTv"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                <FaTwitter></FaTwitter>
              </a>
            </Col>
            <Col xs="2" md="2">
              <a
                href="https://www.facebook.com/OfficialANTV"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                <FaFacebook></FaFacebook>
              </a>
            </Col>
            <Col md="2">
              <Link
                to={{ pathname: "https://opensea.io/Ceo_2010" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/nft.png" width="50" alt="NFT" />
              </Link>
            </Col>
          </Row>
        </Container>
      </Navbar>
      <Container>
        <Row>
          <Col md="2">&copy; {date} Applause Network</Col>
          <Col md="2"></Col>
          <Col md="8">
            <p className="sec">
              This is the business website for Applause Network TV, Inc. We are
              registering a securities offering with the United States
              Securities and Exchange Commission, pursuant to the rules
              governing JOBS Act - Form Reg. D. For information on the Form Reg.
              D project, please visit:{" "}
              <a href="https://regd.antv.tv" rel="noreferrer" target="_blank">
                https://regd.antv.tv
              </a>
              .
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
