import React, { useMemo } from "react";
import "./Home.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import useApi from "../api/use-api";
import { getVideos } from "../api/videos";
import Video from "../models/video";
import { history } from "../history";

const HomeContainer = () => {
  const { data: movies, loading: moviesLoading } = useApi(getVideos);

  const videos = useMemo(() => {
    return !Array.isArray(movies?.movies)
      ? []
      : movies.movies.map(m => new Video(m));
  }, [movies]);

  return (
    <Container>
      <Row xs={1} md={2} className="g-4" id="movies">
        {!moviesLoading &&
          videos.map(movie => (
            <Col md="4" key={movie.id}>
              <Card>
                <Card.Img variant="top" src={movie.thumbnail} />
                <Card.Body className="d-flex flex-column">
                  <Card.Title>{movie.title}</Card.Title>
                  <Card.Text>{movie.shortDescription}</Card.Text>
                  <Card.Text className="d-flex justify-content-between align-items-center">
                    <small className="text-muted">{movie.time}</small>
                  </Card.Text>
                  <div className="mt-auto d-grid gap-2">
                    <Button
                      variant="dark"
                      size="lg"
                      onClick={() => history.push(`/video/${movie.id}`)}
                    >
                      WATCH
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
    </Container>
  );
};

export default HomeContainer;
