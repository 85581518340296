import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Login from "../Login/Login";
import "./TopNav.css";
import { Link } from "react-router-dom";

export default function TopNav() {
  return (
    <Navbar className="bg-pattern" expand="lg" variant="dark">
      <Navbar.Brand sm={8}>
        <Link to="/" className="nav-item nav-link">
          <img
            src="/logo.png"
            width="254"
            height="120"
            className="d-inline-block align-top"
            alt="ANTV Logo"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ms-auto login">
          <Login />
        </Nav>
      </Navbar.Collapse>
      <a href="https://regd.antv.tv" target="_blank" rel="noreferrer" class="nav-item nav-link img-thumbnail">
        <p><strong>SCAN TO INVEST</strong></p>
        <img
          src="/regd_qr.png"
          width="147"
          height="147"
          className="d-inline-block align-top"
          alt="REGD ANTV"
        />
        </a>
    </Navbar>
  );
}
