import { useEffect } from "react";
import TopNav from "./TopNav/TopNav";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Home from "./Home/Home";
import { VideoContainer } from "./Video/Video";
import Footer from "./Footer/Footer";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import "./App.css";

export default function App() {
  useEffect(() => {
    ReactGA.initialize("UA-82048169-2");
    const advancedMatching = {};
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init("347459633271797", advancedMatching, options);
    ReactPixel.pageView();
  }, []);

  return (
    <div className="App bg-dark">
      <Router>
        <TopNav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/video/:id" element={<ProtectedRoute component={VideoContainer} />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

function ProtectedRoute({ component: Component }) {
  return withAuthenticationRequired(Component, {
    onRedirecting: () => <div>Loading...</div>,
  });
}
