import { str_pad_left } from "../util/strings";

export default class Video {
  constructor(video) {
    this.video = video;
  }

  get id() {
    return this.video?.id;
  }

  get thumbnail() {
    return this.video?.thumbnail;
  }

  get url() {
    return this.video?.content?.videos?.[0]?.url;
  }

  get title() {
    return this.video?.title;
  }

  get description() {
    return this.video?.shortDescription;
  }

  get time() {
    const min = Math.floor(this.video?.content?.duration / 60);
    const seconds = this.video?.content?.duration - min * 60;

    return str_pad_left(min, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
  }
}
