import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Auth0Provider } from "@auth0/auth0-react";
import { history } from "./history";

const onRedirectCallback = appState => {
  history.replace(appState?.returnTo || window.location.pathname);
};

const root = createRoot(document.getElementById('root')); // Use createRoot
root.render(
  <Auth0Provider
    domain="antv.us.auth0.com"
    clientId="hvEzCZGw0XpGGeT5HKQbQkEuo1yfSifn"
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>
);

serviceWorkerRegistration.register();
reportWebVitals();