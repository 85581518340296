import React, { useEffect } from "react";
import "./Login.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { checkUserExists, createUser } from "../api/user";

const UserWelcome = () => {
  const { logout, user } = useAuth0();

  return (
    <Row>
      <div className="jumbotron">
        <Container>
          <Row className="justify-content-md-center">
            <Col md="4">
              <Image src={user.picture} alt={user.name} roundedCircle className="profile-pic align-middle" />
            </Col>
            <Col md="8">
              <span className="h3 align-middle text-white">Welcome, {user.name.split(" ")[0]}</span>
            </Col>
          </Row>
        </Container>
      </div>
      <Button variant="secondary" onClick={() => logout({ returnTo: window.location.origin })}>
        Sign Out
      </Button>
    </Row>
  );
};

const Guest = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Row>
      <Container>
        <Row>
          <Col md="12">
            <Button variant="secondary" onClick={() => loginWithRedirect({ appState: { targetUrl: window.location.pathname } })}>
              Login
            </Button>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};

const Login = () => {
  const { isAuthenticated, user } = useAuth0();
console.log("USER: ", user);
console.log("isAuthenticated: ", isAuthenticated);
  useEffect(() => {
    if (isAuthenticated && user) {
      const userDetails = {
        email: user.email,
        first_name: user.given_name || user.name,
        last_name: user.family_name || '',
        auth0_id: user.sub,
      };

      checkUserExists(user.sub)
        .then(existingUser => {
          if (!existingUser) {
            return createUser(userDetails);
          }
          return existingUser;
        })
        .catch(error => console.error('Error checking or creating user:', error));
    }
  }, [isAuthenticated, user]);

  return <Container fluid>{isAuthenticated ? <UserWelcome /> : <Guest />}</Container>;
};

export default Login;
