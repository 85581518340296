import React, { useMemo, useState } from "react";
import { useParams } from "react-router";
import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Video.css";
import { getVideos } from "../api/videos";
import VideoModel from "../models/video";
import { Placeholder } from "react-bootstrap";
import ReactPlayer from 'react-player';
import { CardLoading } from "../components/Loading";
import useApi from "../api/use-api";

const VideoMeta = ({ title, description }) => (
  <Card bg="dark" border="primary">
    <Card.Header as="h2" className="text-orange">
      {title}
    </Card.Header>
    <Card.Body>
      <Card.Text className="text-white">{description}</Card.Text>
    </Card.Body>
  </Card>
);

const Video = ({ video, loading, error }) => {
  if (error) {
    return <h3 style={{ color: "white" }}>There was an error loading the video.</h3>;
  }

  return (
    <Container fluid>
      <Stack gap={1} className="col-md-10 mx-auto">
        <div className="ratio ratio-16x9">
          <div>
            {loading ? (
              <Placeholder animation="glow" />
            ) : (
              <ReactPlayer width="100%" height="100%" url={video.url} controls={true} playing={true} muted={true} />
            )}
          </div>
        </div>
        <div>
          {loading ? (
            <CardLoading />
          ) : (
            <VideoMeta title={video.title} description={video.description} />
          )}
        </div>
      </Stack>
    </Container>
  );
};

export const VideoContainer = () => {
  const { id: videoId } = useParams();
  const { data, loading, error: apiError } = useApi(getVideos);
  const [error, setError] = useState(false);

  const video = useMemo(() => {
    if (loading) return null;

    if (!Array.isArray(data?.movies)) {
      setError(true);
      return null;
    }

    const v = data.movies.find(m => m.id === videoId);
    if (typeof v === "undefined") {
      setError(true);
      return null;
    }

    return new VideoModel(v);
  }, [data, videoId, loading]);

  return (
    <div className="content">
      <Video video={video} loading={loading} error={error || apiError} />
    </div>
  );
};
