import axios from "axios";

const BASE_URL = "https://api.antv.tv";

export const checkUserExists = (auth0Id) => 
  axios
    .get(`${BASE_URL}/users/${auth0Id}`)
    .then(({ data }) => data)
    .catch(error => {
      if (error.response && error.response.status === 404) {
        return null;
      }
      throw error;
    });

export const createUser = (userDetails) => 
  axios
    .post(`${BASE_URL}/users`, userDetails)
    .then(({ data }) => data);
