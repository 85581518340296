import { useEffect, useState } from "react";

const useApi = api => {
  const [state, setState] = useState({
    error: null,
    loading: true,
    data: null,
  });

  useEffect(() => {
    api()
      .then(data => {
        setState({
          loading: false,
          data,
        });
      })
      .catch(error =>
        setState({
          loading: false,
          error,
        })
      );
  }, [api]);

  return state;
};

export default useApi;
